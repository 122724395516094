import React, {
    useState,
    useEffect
} from 'react';
import {
    Box,
    Button,
    Paper,
    Portal,
    Typography,
    Dialog,
    DialogContent, Link
} from '@material-ui/core';
import {paths, ROLES, THEMES} from 'src/constants';
import Pusher from "pusher-js";
import {pusherAPI} from "../../config";
import useAuth from "../../hooks/useAuth";
import {Link as RouterLink} from "react-router-dom";
import {formattedDate} from "../../utils";
import useSound from "use-sound";


const FallAlert = () => {
    const [isOpen, setOpen] = useState(false);
    const {user} = useAuth();
    const [result, setResult] = useState(null);
    const [play, {stop}] = useSound('/static/sounds/ring_cd.wav', {loop: true});

    useEffect(() => {

        if (![ROLES.admin, ROLES.assistant].includes(user?.role?.id)) return;

        const pusher = new Pusher(pusherAPI.key, {
            cluster: pusherAPI.cluster
        })
        const channel = pusher.subscribe(`alert.patient`);
        channel.bind('fall', (data) => {
            handleAlert(data);

            // Code that runs when channel listens to a new message
        })

        return (() => {
            stop();
            pusher.unsubscribe(`alert.patient`);
        })
    }, [play])

    const handleAlert = async (data) => {
        setResult(data);
        setOpen(true);
        play()
    }

    const handleClose = () => {
        setOpen(false);
        stop();
    }

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
        >
            <DialogContent
                elevation={3}
            >
                <Typography
                    variant='h3'
                    color='textPrimary'
                    gutterBottom
                >
                    Paziente caduto
                </Typography>
                <Typography
                    variant='body2'
                    color='textSecondary'
                >
                  {result?.patient?.name} {result?.patient?.surname} è caduto alle {formattedDate( result?.fall?.measureDate, 'specific')}
                </Typography>
                <Box
                    mt={2}
                    display='flex'
                    justifyContent='space-between'
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to={`${paths.assistantDashboard}/${result.fall?.id}`}
                        component={RouterLink}
                        onClick={handleClose}
                    >
                        <Button
                            style={{
                                backgroundColor: 'green'
                            }}
                        >
                            Dettagli
                        </Button>
                    </Link>

                    <Button
                        style={{
                            backgroundColor: "red"
                        }}
                        onClick={handleClose}
                    >
                        Chiudi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default FallAlert;
