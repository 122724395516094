import React, { useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    Typography,
    Button
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import sections from './sections';
import useStyles from './styles';

const canAccess = ({item, user}) => {

    if ( user.role.id == 'ADM' ) {
        return true;
    }

    if ( item.allowedSections ) {
        return item.allowedSections.map( i => user.sections[i]).filter(i => i).length;
    }

    return  !item.allowedSection ||  user.sections[item.allowedSection];

}

const renderNavItems = ({ items, user, pathname, depth = 0 }) => {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, user, pathname, depth }),
                []
            )}
        </List>
    );
};

const reduceChildRoutes = ({ acc, user, pathname, item, depth }) => {
    const key = item.title + depth;

    if (canAccess({item, user})) {
        if (item.items) {
            const open = matchPath(pathname, {
                path: item.href,
                exact: false
            });
            
            acc.push(
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    open={Boolean(open)}
                    title={item.title}
                >
                    {renderNavItems({
                        depth: depth + 1,
                        pathname,
                        items: item.items,
                        user
                    })}
                </NavItem>
            );
        } else {
            acc.push(
                <NavItem
                    depth={depth}
                    href={item.href}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    title={item.title}
                />
            );
        }
    }
    
    
    return acc;
};



const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const { user, logout } = useAuth();
    const history = useHistory();
    
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);
    
    
    const handleLogout = async () => {
        await logout();
        history.push('/');
    };
    
    const getSubheader = ({ section }) => {
        return (
            <>
                {canAccess({item: section, user}) ?
                    <List
                        key={section.subheader}
                        subheader={
                            <ListSubheader disableGutters disableSticky>
                                {section.subheader}
                            </ListSubheader>
                        }
                    >
                        {
                            renderNavItems({
                                items: section.items,
                                pathname: location.pathname,
                                user
                            })
                        }
                    </List> : <></>
                }
            </>
        );
    };
    
    
    const content = (
        <Box height='100%' display='flex' flexDirection='column'>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display='flex' justifyContent='center'>
                        <RouterLink to='/'>
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                
                <Box p={2}>
                    <Box mt={2} textAlign='center'>
                        <Link
                            component={RouterLink}
                            to='/app/account'
                            variant='h5'
                            color='textPrimary'
                            underline='none'
                        >
                            {user.name}{' '}{user.surname}
                        </Link>
                        <Typography variant='body2' color='textSecondary'>
                            Ruolo:{' '}
                            {user.role && user.role.name}
                        </Typography>
                    </Box>
                    <Box mt={2} textAlign='center'>
                        <Button
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {
                        sections.map(section => getSubheader({ section }))
                    }
                </Box>
                <Divider />
            </PerfectScrollbar>
        </Box>
    );
    
    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor='left'
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant='temporary'
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor='left'
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant='persistent'
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
