import {doGet, doPost}  from './FetchWrapper';
import { getKeys } from '../utils/APIUtil';


const search = args  => {
    return doGet(`/api/?event=option.search${getKeys(args)}`)
};

const get = id  => {
    return doGet(`/api/?event=option.get&id=${id}`)
};

const create = data => {
    return doPost(`/api/?event=option.create`, data)
};

const modify = data => {
    return doPost(`/api/?event=option.modify`, data)
};

const remove = id => {
    return doPost(`/api/?event=option.delete`, id)
};



export { search, get, create, modify, remove }
