import {Box} from "@material-ui/core";
import React from "react";

export default ({color}) => {
    return (
        <Box style={{
            height: '25px',
            width: '25px',
            backgroundColor: color,
            borderRadius: '50%',
            display: 'inline-block'
        }}/>
    )
}
