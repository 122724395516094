import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon, Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import {APP_VERSION, DOMAIN_PROPERTIES, THEMES} from 'src/constants';
import Account from '../../Account';
import Settings from '../../Settings';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...theme.name === THEMES.LIGHT ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
        } : {},
        ...theme.name === THEMES.ONE_DARK ? {
            backgroundColor: theme.palette.background.default
        } : {}
    },
    logo: {
        marginRight: theme.spacing(2)
    },
    toolbar: {
        minHeight: 64
    }
}));


const TopBar = ({
                    className,
                    onMobileNavOpen,
                    ...rest
                }) => {
    const classes = useStyles();
    
    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box
                height={5}
                style={{backgroundColor: DOMAIN_PROPERTIES[window.location.host].barColor}}
            />
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton
                        color='inherit'
                        onClick={onMobileNavOpen}
                    >
                        <SvgIcon fontSize='small'>
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to='/'>
                        <Logo className={classes.logo} />
                    </RouterLink>
                    <Typography variant='caption' color='textSecondary'>
                        Version {APP_VERSION}
                    </Typography>
                </Hidden>
                <Box
                    ml={2}
                    flexGrow={1}
                />
                <Settings />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};

export default TopBar;
