import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import {clientDomain} from "../../config";
import Logo from "../../components/Logo";
import {DOMAIN_PROPERTIES} from "../../constants";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingTop: 200,
        paddingBottom: 200,
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
            paddingBottom: 60
        }
    },
    technologyIcon: {
        height: 40,
        margin: theme.spacing(1)
    },
    image: {
        perspectiveOrigin: 'left center',
        transformStyle: 'preserve-3d',
        perspective: 1500,
        '& > img': {
            maxWidth: '90%',
            height: 'auto',
            transform: 'rotateY(-35deg) rotateX(15deg)',
            backfaceVisibility: 'hidden',
            boxShadow: theme.shadows[16]
        }
    },
    shape: {
        position: 'absolute',
        top: 0,
        left: 0,
        '& > img': {
            maxWidth: '90%',
            height: 'auto'
        }
    }
}));

const Hero = ({ className, ...rest }) => {
    const classes = useStyles();
    
    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                        >
                            <Typography variant="h1" color="textPrimary">
                                {DOMAIN_PROPERTIES[window.location.host].projectDescription}
                            </Typography>
                            <br />
                            <Typography color="textPrimary">
                                {DOMAIN_PROPERTIES[window.location.host].projectTitle}
                            </Typography>
                            <br />
                            <Typography>
                                <a style={{color: 'white'}} href="/login">Autenticati</a>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box position="relative">
                            <div className={classes.shape}>
                                <Logo logo={'homeLogo'}/>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
