import { API } from 'src/config';
import { doGet } from './FetchWrapper';
import {getApiUrl} from "./APIconfig";

export const list = (str = "") => {
    return doGet(`/api/?event=lookup.list&str=${str}`);
};

export const listAll = () => {
    return doGet(`/api/?event=lookup.listAll`);
};

export const getMedicalDataFields = () => {
    return doGet(`/api/?event=lookup.getMedicalDataFields`);
};

export const getHouseCallsStatus = () => {
    return doGet(`/api/?event=lookup.getHouseCallsStatus`);
};

export const getRequestStatus = () => {
    return doGet(`/api/?event=lookup.getRequestStatus`);
};

export const getInterventionStatus = () => {
    return doGet(`/api/?event=lookup.getInterventionStatus`);
};

export const getEventStatus = () => {
    return doGet(`/api/?event=lookup.getEventStatus`);
};

export const listContactLabels = () => {
    return doGet(`/api/?event=lookup.listContactLabels`);
};
