import {doGet, doPost} from './FetchWrapper';
import {getKeys} from "../utils/APIUtil";

export const search = (args) => {
    return doGet(`/api/?event=request.search${getKeys(args)}`)
};

export const searchAssigned = (args) => {
    return doGet(`/api/?event=request.searchAssigned${getKeys(args)}`)
};

export const searchByPatient = () => {
    return doGet(`/api/?event=request.searchByPatient`)
};

export const get = (id) => {
    return doGet(`/api/?event=request.get&id=${id}`)
};

export const create = (body) => {
    return doPost(`/api/?event=request.create`, body)
};



export const createForPatient = (body) => {
    return doPost(`/api/?event=request.createForPatient`, body)
};

export const canCreateForPatient = (body) => {
    return doPost(`/api/?event=request.canCreateForPatient`, body)
};


export const cancel = (body) => {
    return doPost(`/api/?event=request.cancel`, body)
};


export const remove = (body) => {
    return doPost(`/api/?event=request.delete`, body)
};


export const modify = (body) => {
    return doPost(`/api/?event=request.modify`, body)
};

export const changeStatus = (body) => {
    return doPost(`/api/?event=event.changeStatus`, body)
};
