import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import Transition from 'src/components/Transition'

const MyDialog = (props) => {
    
    const { elements, handleDelete, open, setOpen } = props;
    
    const deleteSelected = async () => {
        await handleDelete();
        await setOpen(false);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>
                    Conferma cancellazione
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Stai per cancellare {elements.length} elementi
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Annulla
                    </Button>
                    <Button onClick={deleteSelected} color='primary'>
                        Procedi
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyDialog;
