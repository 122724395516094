import React, {
    createContext,
    useReducer
} from 'react';
import * as categoryService from 'src/services/MedicalDataCategoryService';
import * as lookupService from '../services/lookupService';
import * as roleService from '../services/roleService';

const initialAuthState = {
    city: null,
    medicalDataCategories: [],
    constants: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'GETCONSTANTS': {
            const { constants } = action.payload;
            return {
                ...state,
                constants
            };
        }
        case 'SENDPATIENT': {
            const { patient } = action.payload;
            return {
                ...state,
                patient
            };
        }
        case 'SENDCITY': {
            const { city } = action.payload;
            return {
                ...state,
                city
            };
        }
        case 'GETCATEGORIES': {
            const { medicalDataCategories } = action.payload;
            return {
                ...state,
                medicalDataCategories
            };
        }
        
        default: {
            return { ...state };
        }
    }
};

const ConstantsContext = createContext({
    ...initialAuthState,
    sendPatient: () => Promise.resolve(),
    sendCity: () => Promise.resolve(),
    removeCity: () => {
    }
});

export const ConstantsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    
    const sendCity =  (city) => {

        dispatch({
            type: 'SENDCITY',
            payload: {
                city
            }
        });
    };

    const sendPatient =  (patient) => {

        dispatch({
            type: 'SENDPATIENT',
            payload: {
                patient
            }
        });
    };

    const removePatient =  () => {

        dispatch({
            type: 'SENDPATIENT',
            payload: {
                patient: null
            }
        });
    };


    const removeCity =  () => {
        
        dispatch({
            type: 'SENDCITY',
            payload: {
                city: null
            }
        });
    };
    
    const getCategories = async () => {
        
        let medicalDataCategories = state.medicalDataCategories;
        
        if (medicalDataCategories.length === 0) {
            categoryService.list().then(result =>
                dispatch({
                    type: 'GETCATEGORIES',
                    payload: {
                        medicalDataCategories: result.data
                    }
                })
            );
        }
        dispatch({
            type: 'GETCATEGORIES',
            payload: {
                medicalDataCategories
            }
        });
    };
    
    const getConstants = async () => {
        try {
            let constants;
            
            if ( Object.keys(state.constants).length ) {
                constants = state.constants;
                
            } else {
                const lookupResult = await lookupService.listAll();
                const roleResult = await roleService.list();
                
                constants = lookupResult.data;
                constants.roles = roleResult.data;
    
            }
            
            dispatch({
                type: 'GETCONSTANTS',
                payload: {
                    constants: constants
                }
            });
            
        } catch (err) {
            dispatch({
                type: 'GETCONSTANTS',
                payload: {
                    constants: null
                }
            });
        }
    };

    
    return (
        <ConstantsContext.Provider
            value={{
                ...state,
                sendCity,
                sendPatient,
                removePatient,
                removeCity,
                getCategories,
                getConstants
            }}
        >
            {children}
        </ConstantsContext.Provider>
    );
};


export default ConstantsContext;
