import React from 'react';
import { TEXT } from 'src/constants';

export const process = (
    result,
    { setFieldError, setSubmitting }
) => {
    if (result.status === 'ERROR') {
        
        result.errors.map(el => {
            //console.log("el", el);
            let field = el.pointer.replace('/', '').replaceAll('/', '.');
            let point = el.pointer.split('/').join('').toUpperCase();
            
            const msg = getMessage(el.type, point);
            
            setFieldError(field, msg);
        });

        setSubmitting && setSubmitting('N');
    
    }
    
    if (result.status === 'OK') {
        setSubmitting && setSubmitting('Y');
    };
};

const getMessage = (type, point) => {
    
    const lang = 'IT';
    return TEXT[lang]?.[point]?.[type] || 'Campo non valido';
    
};
