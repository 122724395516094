import React, {useState, useEffect} from 'react';
import {
    Box,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import {formattedDate} from 'src/utils';
import * as service from 'src/services/EventService';
import CancelDialog from "./CancelDialog";

const PatientEventTable = () => {

    const [events, setEvents] = useState([]);

    const search = async () => {
        const result = await service.listByPatient();
        setEvents(result.data);
    };

    useEffect(() => {
        search();
    }, []);

    const handleCancel = async ( id ) => {
        try {
            console.log("id", id);
            await service.cancel(id);
        } catch (e) {
        
        }
    }

    console.log("events", events);
    
    return (
        <Box>

            {events?.length ? (

                <Grid container spacing={4}>

                    {events?.map( event => (
                        
                        <Grid item xs={12} md={6} key={event.id}>

                            <Box style={{backgroundColor: '#eaeaea', borderRadius: '4px', color: 'Black'}} p={3}>

                                <Typography variant={'h3'}>
                                    {event.request.service.name}
                                </Typography>
                                <Typography variant={'body1'}>
                                    {formattedDate(event.deadline, 'specific')}
                                </Typography>

                                <Box m={3}>
                                    <CancelDialog  request={event} handleCancel={ ( request )=> handleCancel( event.id ) } />
                                </Box>

                            </Box>
                        </Grid>
                        
                    ))}

                </Grid>                            
                
                ) :
                <Box ml={5}>
                    <Typography>
                        Nessuna richiesta
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export default PatientEventTable;
