import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import PatientEventTable from './PatientEventTable';
import PatientServiceGroupTable from './PatientServiceGroupTable';
import {
    Card,
    CardContent,
    Typography,
    Box
} from '@material-ui/core';
import useStyles from 'src/components/TableComponent/style';

const RequestList = ({className, ...rest}) => {
    const classes = useStyles();
    
    return (
        <Card>
            <CardContent>
                <Typography variant={'h2'}>
                    I tuoi prossimi appuntamenti individuali
                </Typography>

                <Box my={4} />

                <PatientEventTable />

                <Box my={5} />

                <Typography variant={'h2'}>
                    I tuoi prossimi appuntamenti di gruppo
                </Typography>

                <Box my={4} />

                <PatientServiceGroupTable />

            </CardContent>        
        </Card>
                
    );
};

RequestList.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired
};

RequestList.defaultProps = {
    users: []
};

export default RequestList;
