import {Redirect} from 'react-router-dom';
import React from 'react';
import {ROLES, paths} from 'src/constants';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

export function createUIID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getDefaultUrl(roleId) {

    let defaultUrl = '';
    switch (roleId) {
        case ROLES.patient:
            defaultUrl = '/app/patient/services';
            break;
        case ROLES.admin:
            defaultUrl = paths.dashboard;
            break;
        case ROLES.doctor:
        case ROLES.med:
            defaultUrl = paths.patient;
            break;
        case ROLES.assistant:
            defaultUrl = paths.assistantDashboard;
        case ROLES.coordinator:
        case ROLES.socialOperator:
            defaultUrl = paths.assignedRequests;
            break;
    }

    return defaultUrl

}

export function hasContactType(items, type) {
    const count = items.length;

    for (let i = 0; i < count; i++) {
        if (items[i].type.id === type) {
            return true;
        }
    }
}

export const getTypeIcon = (id) => {

    switch (id) {
        case 'P':
            return <PhoneOutlinedIcon/>;
            break;
        case 'E':
            return <EmailOutlinedIcon/>;
            break;
        default:
            throw('TypeId [' + id + '] ' + 'not found');
    }

};

export const getDate = (input, type) => {
    const date = new Date(input)
    const result = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();

    return type === 'specific' ? result.split('.')[0] : result.split('T')[0]
}

export const formattedDate = (input, type) => {
    const date = new Date(input)
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());
    let hours = String(date.getHours());
    let minutes = String(date.getMinutes());
    let seconds = String(date.getSeconds());
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;

    let result = `${day}/${month}/${year}`;

    if (type === 'specific') {
        result = result + ` ${hours}:${minutes}:${seconds}`
    }

    return result;
}

export const defineProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};

export const checkIfMed = user => {
    const roles = ["MED", "MSP"];
    return roles.includes(user.role?.id);
}

export const redirectIfMed = user => {

    if (checkIfMed(user)) {
        return <Redirect to="/404"/>;
    }

}

export const checkIfDuplicate = values => {
    const map = {};
    let hasDuplicates = false;

    values.map((value, i) => {
        if (map[values[i].id]) {
            hasDuplicates = true;
        }
        map[values[i].id] = true
    })

    return hasDuplicates
}

export const addNewContact = (values) => {
    const type = document.getElementById('newContact_type_id')?.value;
    const value = document.getElementById('newContact_value_id')?.value;
    const isReferent = document.getElementById('newContact_is_referent')?.value;
    const label = document.getElementById('newContact_label_id')?.value;
    const description = document.getElementById('newContact_description')?.value;

    if (value.length > 0) {
        values.contacts.push({
            id: 'new-' + createUIID(),
            type: {id: type},
            label: {id: label},
            value: value,
            description: description,
            isReferent: isReferent
        })
    }
    return values;
}
