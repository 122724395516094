import {PieChart as PieChartIcon} from "react-feather";

const sections = [
    {
        title: 'Servizi',
        icon: PieChartIcon,
        href: '/app/patient/services'
    },
    {
        title: 'Appuntamenti',
        icon: PieChartIcon,
        href: '/app/patient/requests'
    }
]

export default sections;
