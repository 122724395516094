import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as service from 'src/services/RequestService';
import * as eventService from 'src/services/EventService';

import RequestTable from './RequestTable';
import AssignedRequestTable from './AssignedRequestTable';
import {
    Box,
    Button,
    Card,
    TablePagination,
    TextField,
    Grid
} from '@material-ui/core';
import {UserAutoComplete} from "../../../components/NmAutoComplete";
import * as lookupSvc from "../../../services/lookupService";
import useStyles from 'src/components/TableComponent/style';
import * as serviceSvc from "src/services/ServiceService";

const Results = ({className, assigned, ...rest}) => {
    const classes = useStyles();
    const [requests, setRequests] = useState([]);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [deadline, setDeadline] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [patientId, setPatientId] = useState('');
    const [statusId, setStatusId] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [status, setStatus] = useState('');
    const [services, setService] = useState([]);
    
    const load = async () => {
        const status = await lookupSvc.getRequestStatus()
        setStatus(status.data)
        const serviceData = await serviceSvc.list();
        await setService(serviceData.data);
        await search();
    };
    
    const search = async () => {
        const params = {page: page + 1, size, deadline, serviceId, patientId, operatorId, statusId}
        const result = assigned ? await eventService.search(params) : await service.search(params);
        
        setRequests(result.data);
        setTotal(result.total);
    };
    useEffect(() => {
        load();
    }, [page, size]);
    
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleLimitChange = event => {
        setSize(parseInt(event.target.value));
    };
    
    const handlePatient = (e, value) => {
        setPatientId(value?.id)
    }
    
    const handleOperator = (e, value) => {
        setOperatorId(value?.id)
    }
    
    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            
            <div className={classes.root} id={'search-fields'}>
                <Box p={2}>
                    <Grid
                        container
                        spacing={3}
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item xs={6} md={6}>
                            <UserAutoComplete
                                label='Cerca paziente'
                                case='pazienti'
                                onChange={handlePatient}
                                value={patientId}
                            />
                        </Grid>
                        {!assigned &&
                            <>
                                <Grid item xs={6} md={6}>
                                    <UserAutoComplete
                                        label='Cerca operatori'
                                        case='operatori'
                                        onChange={handleOperator}
                                        value={operatorId}
                                    />
                                </Grid>
                            
                            </>
                        }
                        
                        {
                            assigned &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id='date'
                                    label='Scadenza'
                                    fullWidth
                                    type='date'
                                    value={deadline}
                                    onChange={(e => setDeadline(e.target.value))}
                                    variant='outlined'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />{' '}
                            </Grid>
                        }
                        
                        
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Stato'
                                fullWidth
                                select
                                value={statusId}
                                variant='outlined'
                                SelectProps={{native: true}}
                                onChange={(event) => setStatusId(event.target.value)}
                            >
                                <option value={-1} key={-1}>
                                    ...Seleziona stato
                                </option>
                                {
                                    status && status.map(option => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        )
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Servizio'
                                fullWidth
                                select
                                value={serviceId}
                                variant='outlined'
                                SelectProps={{native: true}}
                                onChange={(event) => setServiceId(event.target.value)}
                            >
                                <option value={-1} key={-1}>
                                    ...Seleziona servizio
                                </option>
                                {
                                    services && services.map(option => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        )
                                    )
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} alignItems='center'>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={search}
                            >
                                Cerca
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            
            {!assigned ?
                <RequestTable
                    classes={classes}
                    reload={search}
                    requests={requests}
                
                /> :
                <AssignedRequestTable
                    requests={requests}
                
                />
            }
            <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={size}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired
};

Results.defaultProps = {
    users: []
};

export default Results;
