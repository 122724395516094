import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import * as service from 'src/services/AuthService';
import {process} from 'src/services/AjaxResult';
import * as requestUserService from 'src/services/RequestUserService';
import {Redirect, useHistory, useLocation} from 'react-router-dom';

const initialAuthState = {
    isAuthenticated: false,
    user: null,
    isInitialised: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {isAuthenticated, user} = action.payload;


            return {
                ...state,
                isAuthenticated,
                user,
                isInitialised: true
            };
        }
        case 'LOGIN': {
            const { user} = action.payload;

            localStorage.setItem('user', user.id);

            return {
                ...state,
                user,
                isAuthenticated: true
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                user: null,
                isAuthenticated: false
            };
        }

        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {
    },
});

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const history = useHistory();

    const login = async (values, {setFieldError}) => {

        const result = await service.login(values);

        if (result.status === 'ERROR') {
            return process(result, {setFieldError});
        }

        if (result.data.isAuth) {
            const {data} = await requestUserService.get();


            dispatch({
                type: 'LOGIN',
                payload: {
                    user: data
                }
            });

        } else {
            history.push('/pincode')
        }

    }

    const onValidPincode = async () => {
        const {data} = await requestUserService.get();

        dispatch({
            type: 'LOGIN',
            payload: {
                user: data
            }
        })
    }

    const logout = () => {
        localStorage.removeItem('user');
        service.logout();
        dispatch({type: 'LOGOUT'});
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                if (!localStorage.getItem('user')) {
                    return dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
                const response = await requestUserService.get();
                const user = response.data;

                if (user) {

                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };
        initialise();
    }, [initialAuthState.user]);

    if (!state.isInitialised) {
        return <SplashScreen/>;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                onValidPincode,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
