import React, {useEffect} from 'react';
import {
     IconButton, SvgIcon,
    TableCell
} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";
import {Edit as EditIcon} from "react-feather";
import Moment from "react-moment";
import * as service from 'src/services/RequestService';

import TableComponent from "../../../components/TableComponent";
import useConstants from "../../../hooks/useConstants";
import Circle from "../../../components/Circle";

const RequestTable = ({requests, reload}) => {
    const {constants, getConstants} = useConstants();
    
    useEffect(() => {
        getConstants()
    }, [])
    
    const getCells = (record) => {
        return (
        <>
            <TableCell>
                <Circle color={record.status.color}/>
            </TableCell>
            <TableCell>
                {record.status?.name}
            </TableCell>
            <TableCell>
                {record.patient?.name} {record.patient?.surname}
            </TableCell>
            <TableCell>
                {record.operator ? `${record.operator?.name} ${record.operator?.surname}` : 'Non assegnato'}
            </TableCell>
        
            <TableCell>
                {record?.service?.name}
            </TableCell>
            <TableCell>
                {record.description}
            </TableCell>
           
            <TableCell>
                {constants.frequencies ? constants.frequencies.filter(fr => fr.id === record.frequency)[0]?.name : ''}
            </TableCell>
            <TableCell>
                {record.startDate &&
                    <Moment format='DD/MM/YYYY HH:mm'>
                        {record.startDate}
                    </Moment>
                }
            </TableCell>
            <TableCell>
                {record.endDate &&
                    <Moment format='DD/MM/YYYY HH:mm'>
                        {record.endDate}
                    </Moment>
                }
            </TableCell>
            <TableCell >
                <IconButton
                    component={RouterLink}
                    to={`/app/requests/${record.id}`}
                >
                    <SvgIcon fontSize='small'>
                        <EditIcon/>
                    </SvgIcon>
                </IconButton>
            </TableCell>
        </>
        )
    }
    
    return (
        <>
           <TableComponent
            records={requests}
            headers={[
                '',
                'Stato',
                'Paziente',
                'Operatore',
                'Servizio',
                'Descrizione',
                'Frequenza',
                'Data inizio',
                'Data fine',
                'Azioni'
            ]}
            service={service}
            getCells={getCells}
            reload={reload}
           />
        </>
    );
};

export default RequestTable;
