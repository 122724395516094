import React, {useState, useEffect} from 'react';
import {
    Box,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import {formattedDate} from 'src/utils';
import * as service from 'src/services/ServiceService';
import CancelDialog from "./CancelDialog";

const PatienServiceGroupTable = () => {

    const [services, setServices] = useState([]);

    const search = async () => {
        const result = await service.listEventByPatient();
        setServices(result.data);
    };

    useEffect(() => {
        search();
    }, []);

    const handleCancel = async ( id ) => {
        try {
            //console.log("id", id);
            await service.unsubscribe(id);
        } catch (e) {
        
        }
    }

    console.log("groupServices", services);

    return (
        <Box>
            {services?.length ? (

                <Grid container spacing={4}>

                    {services?.map(service => (

                        <Grid item xs={12} md={6} key={service.id}>

                            <Box style={{backgroundColor: '#FFFFCC', borderRadius: '4px', color: 'Black'}} p={3}>

                                <Typography variant={'h3'}>
                                    {service.service.name}
                                </Typography>
                                <Typography variant={'body1'}>
                                    { formattedDate( service.event.deadline, 'specific' ) }
                                </Typography>

                                <Box m={3}>
                                    <CancelDialog 
                                        handleCancel={ ()=> handleCancel( service.id ) } label="Cancella presenza" />
                                </Box>

                            </Box>
                        </Grid>
                        
                    ))}

                </Grid>                            
                
                ) :
                <Box ml={5}>
                    <Typography>
                        Nessuna appuntamento
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export default PatienServiceGroupTable;
