import {doGet, doPost}  from './FetchWrapper';
import { getKeys } from '../utils/APIUtil';


const list = ( typeId )  => {
    return doGet(`/api/?event=service.list&typeId=${typeId}`)
};

const search = args  => {
    return doGet(`/api/?event=service.search${getKeys(args)}`)
};

const get = id  => {
    return doGet(`/api/?event=service.get&id=${id}`)
};

const create = data => {
    return doPost(`/api/?event=service.create`, data)
};

const modify = data => {
    return doPost(`/api/?event=service.modify`, data)
};

const remove = id => {
    return doPost(`/api/?event=service.delete`, id)
};

const listStatus = () => {
    return doGet(`/api/?event=service.listStatus`)
};

const listTypes = () => {
    return doGet(`/api/?event=service.listTypes`)
};

const listEventByPatient = () => {
    return doGet(`/api/?event=service.listEventByPatient`)
};

const addEvent = data => {
    return doPost(`/api/?event=service.addEvent`, data)
};

const modifyEvent = data => {
    return doPost(`/api/?event=service.modifyEvent`, data)
};

const removeEvent = data => {
    return doPost(`/api/?event=service.removeEvent`, data)
};

const subscribe = data => {
    return doPost(`/api/?event=service.subscribe`, data)
};

const unsubscribe = data => {
    return doPost(`/api/?event=service.unsubscribe`, data)
};


export { search, get, create, modify, list, listStatus, listTypes, remove, modifyEvent, removeEvent, addEvent, subscribe, listEventByPatient, unsubscribe };
