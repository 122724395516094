import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Toolbar,
    makeStyles,
    Typography, Button, Hidden, Grid
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import {APP_VERSION, DOMAIN_PROPERTIES, THEMES} from 'src/constants';
import Account from '../Account';
import Settings from '../Settings';
import sections from './sections'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...theme.name === THEMES.LIGHT ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
        } : {},
        ...theme.name === THEMES.ONE_DARK ? {
            backgroundColor: theme.palette.background.default
        } : {}
    },
    logo: {
        marginRight: theme.spacing(2)
    },
    toolbar: {
        minHeight: 64
    }
}));


const TopBar = ({
                    className,
                    onMobileNavOpen,
                    ...rest
                }) => {
    const classes = useStyles();
    
    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box
                height={5}
                style={{backgroundColor: DOMAIN_PROPERTIES[window.location.host].barColor}}
            />
            <Toolbar className={classes.toolbar}>
                <Hidden mdDown>
                    <RouterLink to='/'>
                        <Logo className={classes.logo}/>
                    </RouterLink>
                    <Typography variant='caption' color='textSecondary'>
                        Version {APP_VERSION}
                    </Typography>
                </Hidden>
                {sections.map(section =>
                    <Grid item xs={4}>
                        <Button
                            style={{textAlign: 'center', lineHeight: '15px'}}
                            component={RouterLink}
                            fullWidth
                            to={section.href}
                        >
                            {section.title}
                        </Button>
                    </Grid>
                )}
                
                
                <Account accountPath={'/app/patient/account'}/>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};

export default TopBar;
