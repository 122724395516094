import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@mui/material';

//import { X  } from 'react-feather';

const CancelDialog = ({ handleCancel, label='Annulla appuntamento', question='Vuoi annullare?' }) => {
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };
    
    const cancelRequest = async () => {

        await handleCancel();

        setOpenSnackbar(true);

        setTimeout(() => {
            window.location.reload();
        }, 2500);
        
    }
    
    return (
        <>
            <Button color={"primary"} variant={'outlined'} onClick={handleOpen}
                color={'secondary'}
                fullWidth
                variant="contained"            
            >
                {label}
            </Button>
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={open}
                keepMounted
                onClose={handleOpen}
            >
                <DialogTitle>
                    <Typography variant={'h2'}>
                        {question}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button  variant="outlined" onClick={cancelRequest} color='primary'>
                        Conferma
                    </Button>
                    <Button  variant="outlined" onClick={handleOpen} color='secondary'>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                sx={{ width: '100%' }}
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
            >
                <Alert
                    severity={'success'}
                    sx={{
                        width: '100%',
                        fontSize: '3rem',
                        marginBottom: '60%'
                    }}
                >
                    Ti sei cancellato dal servizio.
                </Alert>
            </Snackbar>

        </>
    );
};
export default CancelDialog;
